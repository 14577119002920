import './App.css';

function ComingSoon() {
	return (
    	<div className="app">
			<h1 className="title">Website under construction</h1>
			<h3 className="text">Coming Soon</h3>
			<h3 className="footer">Please check back for an update soon</h3>
    	</div>
  	);
}

export default ComingSoon;
